import React from "react";
import Input from "./Input.js";
import Select from "./Select.js";
import costs from "../data/costs.json";

const AfterwardService = ({data, setData}) => {
    return (
        <section>
            <h1>Obsługa po realizacji</h1>
            <Select text = "Utrzymanie serwisu (poza okresem gwarancji)" whichData = "utrzymywanieSerwisu" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["utrzymywanieSerwisu"][data.utrzymywanieSerwisu][data.rodzajStrony]} />
            <Select text = "Administrowanie treścią" whichData = "administrowanieTrescia" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["administrowanieTrescia"][data.administrowanieTrescia][data.rodzajStrony]} />
            <Input text = "Inne usługi (miesięczne)" whichData = "inneUslugi" data = {data} setData = {setData} />  
            <Input text = "Inne usługi (miesięczne) koszt" inputType = "number" whichData = "inneUslugiKoszt" data = {data} setData = {setData} />  
        </section>
    )
}

export default AfterwardService;