import React, { useState } from "react";

import CloseIcon from '@material-ui/icons/Close';

const SavedOffers = ({setData}) => {
    const [loadedOffers, setLoadedOffers] = useState([]);
    const [offersLoaded, setOffersLoaded] = useState(false)
    const [loadingOffers, setLoadingOffers] = useState(false);

    const loadOffers = () =>{
        setLoadingOffers(true);
        setOffersLoaded(false);
        fetch("https://johncube-kalkulator.herokuapp.com/get-offers")
        .then(res => res.json())
        .then(data => {
            setLoadedOffers(data);
            setOffersLoaded(true);
            setLoadingOffers(false);
            console.log(offersLoaded);
        });
    }

    const deleteOffer = id => {
      fetch("https://johncube-kalkulator.herokuapp.com/delete-offer",{
          method: 'POST',
          body: JSON.stringify({
            idToDelete: id
          }),
          headers: {
          'Content-Type': 'application/json'
          }
      })
      loadOffers();
    }

    return (
        <div className = "saved-offers">
            <button className="btn btn-primary" onClick = {loadOffers}>Wczytaj oferty</button>
            {
                offersLoaded && <div>
                    {
                        (loadedOffers.length > 0)?loadedOffers.map(offer => {
                            return <div className = "saved-offer" key = {offer.id}>
                                <button className="btn btn-primary" onClick = {() => setData(offer)}>Ustaw Dane</button>
                                {`${offer.saveName} ${offer.created}`}
                                <div className = "delete" onClick = {() => deleteOffer(offer.id)}>
                                    <CloseIcon />
                                </div>
                            </div>
                        }): "Nie zapisano żadnych ofert"
                    }
                </div>
            }
            {
                loadingOffers && <img className = "downloading-gif" src = "images/loading.gif" alt = "downloading" />
            }
        </div>
    )
}

export default SavedOffers;