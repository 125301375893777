import React from "react";
import Input from "./Input.js";
import Select from "./Select.js";
import costs from "../data/costs.json";

const GeneralInfo = ({data, setData}) => {
    return (
        <section>
            <h1>Informacje Ogólne</h1>
            <Input text = "Nazwa produktu" whichData = "nazwaProduktu" data = {data} setData = {setData} />     
            <Select data = {data} setData = {setData} text = "Typ klienta" whichData = "typKlienta" options = {["organizacja", "osoba fizyczna"]} />
            {
                (data.typKlienta === "organizacja") && 
                <>
                    <Input text = "Nazwa" whichData = "nazwa" data = {data} setData = {setData} />
                    <Input text = "Branża" whichData = "branza" data = {data} setData = {setData} />               
                </>
            }
            <Select text = "Oczekiwany termin realizacji" whichData = "terminRealizacji" options = {["w ciągu miesiąca", "w ciągu 3 miesięcy", "do uzgodnienia"]} data = {data} setData = {setData} price = {`${costs["terminRealizacji"][data.terminRealizacji]}%`} />
            <Select text = "Aktualna domena" whichData = "aktualnaDomena" options = {["tak", "nie"]} data = {data} setData = {setData} />
            <Input text = {(data.aktualnaDomena === "tak")? "Adres aktualnej domeny": "Adres proponowanej strony"} whichData = "adresAktualnejDomeny" data = {data} setData = {setData} />
        </section>
    )
}

export default GeneralInfo;