import React, {useEffect, useState} from "react";
import { saveAs } from 'file-saver';
import costs from "../data/costs.json";

const Summary = ({data, setData}) => {
    const vat = 1.23;
    const [offerName, setOfferName] = useState("");
    const [kosztyPodstawowe,setKosztyPodstawowe] = useState(0);
    const [kosztyWycen,setKosztyWycen] = useState(0);
    const [bonusProcentowy,setBonusProcentowy] = useState(0);
    const [kosztyOferta,setKosztyOferta] = useState(0);
    const [zaliczka, setZaliczka] = useState(0);
    const [utrzymywanieSerwisu, setUtrzymywanieSerwisu] = useState(0);
    const [utrzymanieTresci, setUtrzymanieTresci] = useState(0);
    const [monthlyCosts, setMonthlyCosts] = useState(0);
    const [kosztyProgramistyczne, setKosztyProgramistyczne] = useState(0);
    const [kosztyGraficzne, setKosztyGraficzne] = useState(0);
    const [downloading, setDownloading] = useState(false);

    const generatePDF = () => {
        setDownloading(true);
        fetch("https://johncube-kalkulator.herokuapp.com/generate-pdf",{
            method: 'POST',
            body: JSON.stringify({
                data: data,
                kosztyOferta: kosztyOferta,
                zaliczka: zaliczka,
                monthlyCosts: monthlyCosts,
            }),
            headers: {
            'Content-Type': 'application/json'
            }
        })
        .then(res => {
            setDownloading(false);
            return res.blob();
        })
        .then(blob => saveAs(blob, `${offerName === ""? "oferta":offerName}.pdf`));
    }

    const formatPrice = price => {
        price = (Math.round(parseFloat(price) * 100) / 100).toString(); // upewniamy się, że jest to int, zaokrąglamy do 2 miejsc po przecinku i zamieniamy w stringa.
        if(price.includes(".")){
            let [numb, decimal] = price.split(".");
            if(decimal.length === 1)
                decimal += 0;
            price = `${numb},${decimal}`
        }
        else{
            price = `${price},00`;
        }
        return price;
    }

    const obliczKosztyPodstawowe = () => {
        let koszta;
        koszta = costs["rodzajStrony"][data.rodzajStrony] + 
        costs["projektGraficzny"][data.projektGraficzny] + 
        costs["projektLogo"][data.projektLogo] +  
        costs["ksiegaZnaku"][data.ksiegaZnaku] +  
        (costs["przewidywanaIloscPodstron"]["sztuka"] * data.przewidywanaIloscPodstron) +
        costs["galerieZdjec"][data.galerieZdjec] +  
        costs["filmy"][data.filmy] +  
        costs["portfolio"][data.portfolio] +  
        costs["katalogProduktow"][data.katalogProduktow] +  
        costs["formularzKontaktowy"][data.formularzKontaktowy] +  
        costs["mapy"][data.mapy] +  
        costs["blog"][data.blog] +  
        costs["newsletter"][data.newsletter] +  
        costs["socialMedia"][data.socialMedia] +  
        costs["WCAG"][data.WCAG] +
        costs["ciasteczka"][data.ciasteczka];
        if(data.rodzajStrony === "sklep"){
            koszta += (costs["iloscProduktow"]["sztuka"] * data.iloscProduktow) +  
            costs["integracjaZPlatnosciami"][data.integracjaZPlatnosciami] +  
            costs["subskrypcje"][data.subskrypcje] +  
            costs["automatycznaFaktura"][data.automatycznaFaktura] +  
            costs["metodyDostawy"][data.metodyDostawy] +  
            costs["integracjaZSystemamiFirmKurierskich"][data.integracjaZSystemamiFirmKurierskich] +  
            costs["kodyRabatowe"][data.kodyRabatowe] +  
            costs["maileTransakcyjne"][data.maileTransakcyjne] +  
            costs["obserwowane"][data.obserwowane] +  
            costs["rozbudowanePromocje"][data.rozbudowanePromocje] +  
            costs["porzuconyKoszyk"][data.porzuconyKoszyk] +  
            costs["porownywarka"][data.porownywarka] +  
            costs["logowaniePrzezGoogleFb"][data.logowaniePrzezGoogleFb] 
        }
        if(data.projektGraficzny === "indywidualny")
            koszta += costs["projektGraficzny"]["koszt podstrony"] * data.przewidywanaIloscPodstron;
        setKosztyPodstawowe(koszta);
    }

    const obliczKosztyWycen = () => {
        setKosztyWycen(data.requirements.reduce((total,input) => {
            return total + parseInt(input.cost);
        },0))
    }

    const obliczBonusProcentowy = () => {
        setBonusProcentowy(
            costs["terminRealizacji"][data.terminRealizacji] + 
            (costs["iloscJezykow"]["sztuka"] * (data.iloscJezykow - 1)) + 
            costs["kompletMaterialow"][data.kompletMaterialow]
        );
    }

    const obliczKosztyOferta = () => {
        setKosztyOferta(
            (kosztyPodstawowe + kosztyWycen) * bonusProcentowy / 100 + kosztyPodstawowe + kosztyWycen
        );
    }

    const obliczZaliczka = () => {
        setZaliczka(costs["typKlienta"][data.typKlienta] * kosztyOferta / 100);
    }

    const obliczSerwis = () => {
        if(data.utrzymywanieSerwisu === "tak")
            setUtrzymywanieSerwisu(costs["utrzymywanieSerwisu"][data.utrzymywanieSerwisu][data.rodzajStrony]);
        else
            setUtrzymywanieSerwisu(costs["utrzymywanieSerwisu"][data.utrzymywanieSerwisu]);
    }

    const obliczTresci = () => {
        if(data.administrowanieTrescia === "tak")
            setUtrzymanieTresci(costs["administrowanieTrescia"][data.administrowanieTrescia][data.rodzajStrony]);
        else
            setUtrzymanieTresci(costs["administrowanieTrescia"][data.administrowanieTrescia]);
    }

    const obliczMonthlyCosts = () => {
        setMonthlyCosts(
            (costs["wykupDomeny"][data.wykupDomeny] / 12) +
            (costs["hosting"][data.hosting] / 12) + 
            utrzymywanieSerwisu +
            utrzymanieTresci +
            parseInt(data.inneUslugiKoszt)
        )
    }

    const obliczKosztyGraficzne = () =>{
        let koszta = costs["projektGraficzny"][data.projektGraficzny] +
        costs["projektLogo"][data.projektLogo] +
        costs["ksiegaZnaku"][data.ksiegaZnaku];
        
        if(data.projektGraficzny === "indywidualny")
            koszta += costs["projektGraficzny"]["koszt podstrony"] * data.przewidywanaIloscPodstron;
        setKosztyGraficzne(koszta + koszta * bonusProcentowy / 100);
    }

    const obliczKosztyProgramistyczne = () =>{
        
        setKosztyProgramistyczne(kosztyOferta - kosztyGraficzne);
    }

    const saveOffer = () => {
      fetch("https://johncube-kalkulator.herokuapp.com/add-offer",{
          method: 'POST',
          body: JSON.stringify({
              offer: data,
              saveName: offerName
          }),
          headers: {
          'Content-Type': 'application/json'
          }
      })
    }

    useEffect(() => {
        obliczKosztyPodstawowe();
        obliczKosztyWycen();
        obliczBonusProcentowy();
        obliczSerwis();
        obliczTresci();
    },[data]);

    useEffect(() => {
        obliczKosztyOferta();
        obliczZaliczka();
        obliczKosztyGraficzne();
        obliczKosztyProgramistyczne();
    },[kosztyPodstawowe, kosztyOferta, kosztyWycen, bonusProcentowy, kosztyGraficzne, data]);

    useEffect(() => {
        obliczMonthlyCosts();
    },[utrzymywanieSerwisu, utrzymanieTresci, data]);

    useEffect(() => {
        setData(
            {
                ...data,
                saveName: offerName
            }
        );
    }, [offerName]);
    return (
        <section className = "summary">
            <h1>Podsumowanie</h1>
            <div className = "summary-grid">
                <div></div>
                <div>netto</div>
                <div>brutto</div>

                <div>Koszty podstawowe</div>
                <div>{formatPrice(kosztyPodstawowe)}zł</div>
                <div>{formatPrice(kosztyPodstawowe * vat)}zł</div>

                <div>Koszty wycen indywidualnych</div>
                <div>{formatPrice(kosztyWycen)}zł</div>
                <div>{formatPrice(kosztyWycen * vat)}zł</div>

                <div>Bonus/Malus</div>
                <div>{bonusProcentowy}%</div>
                <div>{bonusProcentowy}%</div>

                <div>Koszty graficzne</div>
                <div>{formatPrice(kosztyGraficzne)}zł</div>
                <div>{formatPrice(kosztyGraficzne * vat)}zł</div>

                <div>Koszty programistyczne</div>
                <div>{formatPrice(kosztyProgramistyczne)}zł</div>
                <div>{formatPrice(kosztyProgramistyczne * vat)}zł</div>

                <div>Koszty (oferta dla Klienta)</div>
                <div>{formatPrice(kosztyOferta)}zł</div>
                <div>{formatPrice(kosztyOferta * vat)}zł</div>

                <div>Zaliczka</div>
                <div>{formatPrice(zaliczka)}zł</div>
                <div>{formatPrice(zaliczka * vat)}zł</div>
            </div>
            <h4>Koszty obsługi(miesięcznie)</h4>
            <div className = "summary-grid">
                <div></div>
                <div>netto</div>
                <div>brutto</div>

                <div>Domena</div>
                <div>{formatPrice(costs["wykupDomeny"][data.wykupDomeny] / 12)}zł</div>
                <div>{formatPrice(costs["wykupDomeny"][data.wykupDomeny] / 12 * vat)}zł</div>

                <div>Hosting</div>
                <div>{formatPrice(costs["hosting"][data.hosting] / 12)}zł</div>
                <div>{formatPrice(costs["hosting"][data.hosting] / 12 * vat)}zł</div>

                <div>Serwis</div>
                <div>{formatPrice(utrzymywanieSerwisu)}zł</div>
                <div>{formatPrice(utrzymywanieSerwisu * vat)}zł</div>

                <div>Treści</div>
                <div>{formatPrice(utrzymanieTresci)}zł</div>
                <div>{formatPrice(utrzymanieTresci * vat)}zł</div>

                <div>Inne</div>
                <div>{formatPrice(data.inneUslugiKoszt)}zł</div>
                <div>{formatPrice(data.inneUslugiKoszt * vat)}zł</div>

                <div>Razem</div>
                <div>{formatPrice(monthlyCosts)}zł</div>
                <div>{formatPrice(monthlyCosts * vat)}zł</div>

                <div></div>
            </div>
            <div>
                <input type = "Text" placeholder = "nazwa oferty" value = {offerName} onChange = {e => setOfferName(e.target.value)} />
                <button className="btn btn-primary" onClick = {saveOffer}>Zapisz oferte</button><br/> <br/>
                <button className="btn btn-primary" onClick = {generatePDF}>generate pdf</button>
                {downloading && 
                    <img className = "downloading-gif" src = "images/loading.gif" alt = "downloading" />}    
            </div>
        </section>
    )
}

export default Summary;