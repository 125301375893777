import React, {useState, useEffect} from "react";
import Input from "./Input.js";
import Select from "./Select.js";
import costs from "../data/costs.json";

const TechnicalInfo = ({data, setData}) => {
    const [graphicPerPage, setGraphicPerPage] = useState(0);

    useEffect(() => {
        setGraphicPerPage(data.projektGraficzny === "indywidualny"? costs["projektGraficzny"]["koszt podstrony"] * data.przewidywanaIloscPodstron: 0);
    },[data]);

    return (
        <section>
            <h1>Parametry techniczne</h1>
            <Input text = "Do czego ma być ta strona" whichData = "doCzegoStrona" data = {data} setData = {setData} />  
            <Select text = "Rodzaj strony" whichData = "rodzajStrony" options = {["wizytówka", "standard", "sklep", "biznes", "inne"]} data = {data} setData = {setData} price = {costs["rodzajStrony"][data.rodzajStrony]} />
            <Select text = "Wykup domeny" whichData = "wykupDomeny" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["wykupDomeny"][data.wykupDomeny]} />
            <Select text = "Hosting" whichData = "hosting" options = {["tak personal", "tak biznes", "nie"]} data = {data} setData = {setData} price = {costs["hosting"][data.hosting]} />
            <Select text = "Projekt graficzny" whichData = "projektGraficzny" options = {["własny", "szablon", "indywidualny"]} data = {data} setData = {setData} price = {costs["projektGraficzny"][data.projektGraficzny] + graphicPerPage} />
            <Select text = "Projekt logo" whichData = "projektLogo" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["projektLogo"][data.projektLogo]} />
            <Select text = "Księga znaku" whichData = "ksiegaZnaku" options = {["tak proste", "tak biznes", "nie"]} data = {data} setData = {setData} price = {costs["ksiegaZnaku"][data.ksiegaZnaku]} />
            <Input text = "Przewidywana ilość podstron" inputType = "number" whichData = "przewidywanaIloscPodstron" data = {data} setData = {setData} price = {costs["przewidywanaIloscPodstron"]["sztuka"] * data.przewidywanaIloscPodstron} />  
            <Input text = "Ilość Języków" whichData = "iloscJezykow" inputType = "number" data = {data} setData = {setData} price = {`${costs["iloscJezykow"]["sztuka"] * (data.iloscJezykow - 1)}%`} />  
            <Select text = "Komplet materiałów" whichData = "kompletMaterialow" options = {["gotowe", "będą przygotowane"]} data = {data} setData = {setData} price = {`${costs["kompletMaterialow"][data.kompletMaterialow]}%`} />
            <Select text = "Dostarczenie treści i materiałów" whichData = "dostarczenieTresciIMaterialow" options = {["po stronie klienta", "oczekiwana pomoc"]} data = {data} setData = {setData} />
            <Select text = "Tłumaczenia wersji językowych" whichData = "tlumaczeniaWersjiJezykowych" options = {["po stronie klienta", "oczekiwana pomoc"]} data = {data} setData = {setData} />
        </section>
    )
}

export default TechnicalInfo;