import React from "react";
import Select from "./Select.js";
import costs from "../data/costs.json";

const AdditionalFunctionalities = ({data, setData}) => {
    return (
        <section>
            <h1>Dodatkowe funkcjonalności</h1>
            <Select text = "Galerie zdjęć" whichData = "galerieZdjec" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["galerieZdjec"][data.galerieZdjec]} />
            <Select text = "Filmy" whichData = "filmy" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["filmy"][data.filmy]} />
            <Select text = "Portfolio" whichData = "portfolio" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["portfolio"][data.portfolio]} />
            <Select text = "Katalog produktów" whichData = "katalogProduktow" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["katalogProduktow"][data.katalogProduktow]} />
            <Select text = "Formularz kontaktowy" whichData = "formularzKontaktowy" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["formularzKontaktowy"][data.formularzKontaktowy]} />
            <Select text = "Mapy" whichData = "mapy" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["mapy"][data.mapy]} />
            <Select text = "Blog" whichData = "blog" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["blog"][data.blog]} />
            <Select text = "Newsletter" whichData = "newsletter" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["newsletter"][data.newsletter]} />
            <Select text = "Integracja z social media" whichData = "socialMedia" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["socialMedia"][data.socialMedia]} />
            <Select text = "WCAG (ponad podstawowy)" whichData = "WCAG" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["WCAG"][data.WCAG]} />
            <Select text = "SEO (ponad podstawowe)" whichData = "SEO" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["SEO"][data.SEO]} />
            <Select text = "Ciasteczka (RODO)" whichData = "ciasteczka" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["ciasteczka"][data.ciasteczka]} />
        </section>
    )
}

export default AdditionalFunctionalities;