import React from "react";
import Input from "./Input.js";
import Select from "./Select.js";
import costs from "../data/costs.json";

const ShopFunctions = ({data, setData}) => {
    return (
        <section>
            <h1>Funkcje dla sklepu</h1>
            <Input text = "Ilość produktów" inputType = "number" whichData = "iloscProduktow" data = {data} setData = {setData} price = {costs["iloscProduktow"]["sztuka"] * data.iloscProduktow} />  
            <Select text = "Integracja z płatnościami" whichData = "integracjaZPlatnosciami" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["integracjaZPlatnosciami"][data.integracjaZPlatnosciami]} />
            <Select text = "Subskrypcje" whichData = "subskrypcje" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["subskrypcje"][data.subskrypcje]} />
            <Select text = "Automatyczna faktura" whichData = "automatycznaFaktura" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["automatycznaFaktura"][data.automatycznaFaktura]} />
            <Select text = "Metody dostawy" whichData = "metodyDostawy" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["metodyDostawy"][data.metodyDostawy]} />
            <Select text = "Integracja z systemami firm kurierskich" whichData = "integracjaZSystemamiFirmKurierskich" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["integracjaZSystemamiFirmKurierskich"][data.integracjaZSystemamiFirmKurierskich]} />
            <Select text = "Kody rabatowe" whichData = "kodyRabatowe" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["kodyRabatowe"][data.kodyRabatowe]} />
            <Select text = "Maile transakcyjne" whichData = "maileTransakcyjne" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["maileTransakcyjne"][data.maileTransakcyjne]} />
            <Select text = "Obserwowane" whichData = "obserwowane" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["obserwowane"][data.obserwowane]} />
            <Select text = "Rozbudowane promocje" whichData = "rozbudowanePromocje" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["rozbudowanePromocje"][data.rozbudowanePromocje]} />
            <Select text = "Porzucony koszyk" whichData = "porzuconyKoszyk" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["porzuconyKoszyk"][data.porzuconyKoszyk]} />
            <Select text = "Porównywarka" whichData = "porownywarka" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["porownywarka"][data.porownywarka]} />
            <Select text = "Logowanie przez Google, FB" whichData = "logowaniePrzezGoogleFb" options = {["tak", "nie"]} data = {data} setData = {setData} price = {costs["logowaniePrzezGoogleFb"][data.logowaniePrzezGoogleFb]} />
        </section>
    )
}

export default ShopFunctions;