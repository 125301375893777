import Input from "./Input.js";
import React from "react";

const Budget = ({data, setData}) => {
    return (
        <section>
            <h1>Budżet</h1>
            <Input text = "6. Czy Klient ma określony budżet (wpisać jaki)" inputType = "number" whichData = "budzet" data = {data} setData = {setData} />  
        </section>
    )
}

export default Budget;