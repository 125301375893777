import React from "react";

const Input = ({data, setData, text, whichData, inputType = "text", price}) => {
    return(
        <label>
            {text}:
            <input type = {inputType} min = "0" value = {data[whichData]} onChange = {e => setData({...data, [whichData]: e.target.value})} />
            <div className = "price">
                {price && price}
            </div>
        </label>
    )
}

export default Input;