import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import SavedOffers from "./components/savedOffers"
import PersonalData from "./components/PersonalData";
import GeneralInfo from "./components/GeneralInfo";
import TechnicalInfo from "./components/TechnicalInfo";
import AdditionalFunctionalities from "./components/AdditionalFunctionalities";
import ShopFunctions from "./components/ShopFunctions";
import AfterwardService from "./components/AfterwardService";
import Budget from "./components/Budget";
import Other from "./components/Other";
import Summary from "./components/Summary";

import startValues from "./data/startValues.json";

const App = () =>{
  const [data, setData] = useState(startValues);

  const addInput = () =>{
      setData({...data, requirements: [...data.requirements, {id: uuidv4(), require: "", cost: 0}]});
  }

  const deleteInput = id =>{
    setData(
      {
        ...data,
        requirements: data.requirements.filter(requirement => {
          return requirement.id !== id;
        })
      }
    )
  }

  const handleInput = (e, id, key) =>{
      setData({
          ...data,
          requirements: data.requirements.map(requirement => {
              if(requirement.id === id)
                  return {...requirement, [key]: e.target.value}
              return requirement
          })
        }
      )
  }

  return (
      <div className="app">
        <div className = "forms">
          <SavedOffers setData = {setData} />
          <PersonalData data = {data} setData = {setData} />
          <GeneralInfo data = {data} setData = {setData} />
          <TechnicalInfo data = {data} setData = {setData} />
          <AdditionalFunctionalities data = {data} setData = {setData} />
          {
            data.rodzajStrony === "sklep" && 
            <ShopFunctions data = {data} setData = {setData} />
          }
          <AfterwardService data = {data} setData = {setData} />
          <Budget data = {data} setData = {setData} />
        </div>
        <div className = "right-fixed">
          <Other requirements = {data.requirements} addInput = {addInput} deleteInput = {deleteInput} handleInput = {handleInput} />
          <Summary data = {data} setData = {setData} />
        </div>
      </div>
  );
}

export default App;
