import React from "react";
import Input from "./Input.js";

const PersonalData = ({data, setData}) => {
    return (
        <section>
            <h1>Osoba kontaktowa</h1>
            <Input data = {data} setData = {setData} text = "Imie nazwisko" whichData = "imie" />
            <Input data = {data} setData = {setData} text = "Adres E-mail" whichData = "email" />
            <Input data = {data} setData = {setData} text = "Nr. Telefonu" whichData = "tel" />
        </section>
    )
}

export default PersonalData;