import React from "react";
import { v4 as uuidv4 } from 'uuid';

const Select = ({data, setData, text, whichData, options, price}) => {
    return(
        <label>
            {text}: 
            <select value = {data[whichData]} onChange = {e => setData({...data, [whichData]: e.target.value})} >
                {
                    options.map(option => {
                        return <option key = {uuidv4()} value = {option}>{option}</option>
                    })    
                }
            </select>
            <div className = "price">
                {price && price}
            </div>
        </label>
    )
}

export default Select;