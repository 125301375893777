import React from "react";
import CloseIcon from '@material-ui/icons/Close';

const Other = ({requirements, addInput, handleInput, deleteInput}) => {
    return (
        <section>
            <h1>Inne oczekiwania</h1>
            {
                requirements.map(requirement => {
                    return (
                        <div className = "requirement" key = {requirement.id}>  
                            <label>
                                Inne oczekiwania klienta:
                                <input type = "text" defaultValue = {requirement.require} onChange = {e => handleInput(e,requirement.id, "require")} />
                            </label>
                            <label>
                                Wycena indywidualna:
                                <input type = "number" defaultValue = {requirement.cost} onChange = {e => handleInput(e,requirement.id, "cost")} />
                            </label>
                            <div className = "delete" onClick = {() => deleteInput(requirement.id)}>
                                <CloseIcon />
                            </div>
                        </ div>
                    )
                })
            }
            <button type="button" className="btn btn-primary" onClick = {addInput}>Dodaj oczekiwanie</button>
        </section>
    )
}

export default Other;